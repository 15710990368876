import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5b3c88'
    },
    secondary: {
      main: '#000'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
})

export default theme
